import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="About us" pgTitle="Overview">
    <section className="section-aboutus">

      <fieldset>
        <h4>About Parakh®:</h4>
        <p>
          Parakh is a volunteer led, non-profit organization, established in 2010 to serve the needy predominantly in Madhya Pradesh &amp; Chhattisgarh. Parakh is committed to create long-term social change that will help individuals to have stability in terms of education and health.
        </p>
        <p>Parakh is registered as a Society under Madhya Pradesh Societies Registration Act 2001 and is legally compliant with 80G, 12A.</p>

          <h4>Mission:</h4>
          <ul>
            <li>Bringing community inside the classroom</li>
            <ul>
              <li>Community contributing to the academic development of the child.</li>
              <li>Improved Community interaction and presence in schools.</li>
              <li>Support structure for the government system to effectively work on the long-term education plan of the tribal children.</li>
            </ul>

            <li>Help teacher save time</li>
            <ul>
              <li>e-attendance  &amp; e-Marks sheet(digitally signed) for Student, and Teacher diary.</li>
              <li>Historical online student health and academic portfolio.</li>
            </ul>

            <li>Help Govt. and NGOs to analyse data</li>
            <ul>
              <li>Report generation for analysis on various parameters (health, achievement, scholarships, financial-status, skills repository etc.) is automated and can be shared/accessed by NGOs, government authorities or any other interested parties.</li>
              <li>Teachers, Jan Shikshak, education executive/expert, and Parents collaboration.</li>
            </ul>

            <li>Environment Protection</li>
            <ul>
              <li>Paperless school (except text book)</li>
              <li>Paperless education department/office</li>
            </ul>

            <li>The Millennium Development Goals</li>
            <ul>
              <li>Contribute to the achievement of MDGs 2 and 8, as well as to MDG 7 on go green. (http://www.in.undp.org/content/india/en/home/mdgoverview)</li>
            </ul>

          </ul>

          <h4>Vision:</h4>
          <ul>
            <li>Healthy and Educated India, Prosperous India. (स्वस्थ और शिक्षित भारत, समृद्ध भारत...)</li>
            <li>A better community linkage to improve the academic quality in primary education system where there is a two-way relationship between the education system and the community.</li>
            <li>Development of human resources to achieve health and academic excellence with optimal quality by the use of information technology.</li>
          </ul>

          <h4>Strategy:</h4>
          <ul>
            <li>To bring together stakeholders on to a common platform to implement sustainable and impactful community development projects.</li>
            <li>Aims to operate at a lower cost by using existing frameworks like SSA, RGSM, RMSA, SCERT, DIET</li>
          </ul>

          <h4>Key Focus Area:</h4>
          <ul>
            <li>Education, Health and Livelihoods</li>
          </ul>


          <hr/>
      </fieldset>

      <fieldset className="info-msg">
        <p>
          शिक्षा का मतलब है..... बच्चों के जीवन को अलग-अलग हिस्सों में नहीं बल्कि समग्रता में समझने के लिए
          शिक्षित करना, जैसे कि शारीरिक, भावात्मक, मानसिक, मनोवैज्ञानिक और आध्यात्मिक । एक विभाजित या टुकड़ों
          में बँटा दृष्टिकोण नहीं, बल्कि जीवन के प्रति एक समग्र एकीकृत दृष्टिकोण..... शिक्षा के माध्यम से हमें एक
          मानव को विकसित करना है जो रचनात्मक है, जो सभी कुछ करने योग्य है, जिसके पास ऐसी बुद्धि है जो कि
          न तो बोझ से लदी हुई है और न ही जिसे किसी दिशा विशेष में ढाला गया है, पर अपने आप में 'समग्र' है।
          यह मानव जो किसी समाज, जाति और धर्म विशेष का नहीं है, वह अपनी जीवन का निर्माण एक टैक्नीशियन
          नहीं बल्कि मानव रूप में करता है।
        </p>
        <p>
          परख शिक्षण सेवा संस्था® द्वारा शिक्षा के क्षैत्र में प्रत्येक विद्यालय के रेकार्ड को हमेषा अपडेट रखने एवं मध्यप्रदेश के सभी विद्यालयों के लिये कक्षा 1 से 12 तक (राज्य शिक्षा केन्द्र के निर्देषानुसार) मूल्यांकन कार्य को सरल बनाने के उद्देश्य से एक साफ्टवेअर का निर्माण किया गया है,
          यह सॉफ्टवेयर पूर्ण रूप से ऑनलाइन है, जिस पर आप कहीं भी कभी भी इन्टरनेट के द्वारा अपने विद्यालय का रेकार्ड अपडेट एवं मूल्यांकन कार्य की प्रविष्ठी कर सकते है। तथा विद्यालय द्वारा समस्त प्रकार की जानकारी जो लिखकर बनाई जाती है, या जिसे बनाने के लिये विद्यालय को अन्य स्त्रोतों से कार्य करवाना पड़ता है, उसे अब साफ्टवेअर के माध्यम से एक क्लिक भर करने से तैयार कर सकते है।
          आपका डाटा पूर्ण रूप से सुरक्षित रहेगा, क्योंकि इसके लिये हमारे द्वारा आपको एक यूज़र नेम तथा पासवर्ड दिया जायेगा, जिससे आपके द्वारा प्रविष्ठ किया गया डाटा सिर्फ आप ही देख सकेंगे।
        </p>

        <p>
          साफ्टवेअर के द्वारा आप अपनी विद्यालय का निम्नलिखित रेकार्ड कम्प्यूटरीकृत/कंप्यूटर द्वारा तैयार कर सकते हैं।
        </p><ul>
        <li>विद्यालय में प्रवेष लेने का आवेदन पत्र</li>
        इसमें आप अपने विद्यालय का प्रवेष आवेदन फार्म छात्रों के लिये तैयार कर सकते हैं। जिसे विद्यालय में प्रवेष लेने वाले छात्रों को आप दे सकते है।
        <li>छात्रों का (कलर्ड) आई डी कार्ड (परिचयपत्र)।</li><li>
        इसमें आप प्रत्येक बच्चे का आईडी कार्ड (परिचय-पत्र)तैयार कर सकते है जो एक निर्धारित साइज़ (एटीएम साईज़) का रहेगा। जिसमें बच्चे की सभी आवश्यक जानकारी रहेगी।
      </li><li>प्रत्येक बच्चे का षिक्षण शुल्क (फीस) कार्ड।</li>
        इस सुविधा के द्वारा आप प्रत्येक बच्चे का षिक्षण शुल्क कार्ड (फीस कार्ड) तैयार कर सकते हैं। जिसमें छात्रों के द्वारा समय समय पर जमा फीस की इन्ट्री आप करके दे सकते हो।
        <li>शिक्षण शुल्क (फीस) की कम्प्यूटराइज्ड रसीद।</li>
        इसके द्वारा आप छात्रों को जमा फीस की रसीद भी दे सकते हो। जब भी छात्र के द्वारा फीस जमा की जायेगी आप उसी समय अपने कम्प्यूटर से उसकी रसीद प्रिंट करके दे सकते हो। इसके लिये आपको ली जाने वाली फीस के चार्ट की एक बार इन्ट्री करना होगी। फिर आप कभी भी किसी भी कक्षा के छात्र की किसी भी माह की जमा फीस की रसीद उसी समय प्रिंट करके दे सकते हो।
        <li>छात्र की फीस का विवरण।</li>
        इसके द्वारा आप किसी भी कक्षा के किसी भी छात्र की बकाया फीस का विवरण चेक कर सकते हो, ताकि आपको पता चल जायेगा कि किस छात्र की कौन से माह की फीस बाकि है।
        <li>छात्रवृत्ति।</li>
        इस माड्यूल के द्वारा आप सभी पात्र छात्रों के राज्य छात्रवृत्ति के परिषिष्ट शासन के निर्देषानुसार जातिवार तैयार कर सकते हैं, जिसमें छात्रवृत्ति की दर, कुल राषि आदि सभी जानकारी भरी हुई रहेगी। तथा वरिष्ट कार्यालय में स्वीकृति हेतु भेज सकते है।
        <li>छात्रवृत्ति के बिल जो बैंक मे लगाये जाते है, तैयार हो जायेंगे।</li>
        वरिष्ट कार्यालय से स्वीकृति आदेष मिलने पर आपको छात्रवृत्ति के बिल तैयार करने होते है, जो कि साफ्टवेअर के द्वारा स्वतः निर्धारित प्रारूप में तैयार कर दिये जाते हैं। सिर्फ स्वीकृति आदेष क्रमांक और दिनांक की इन्ट्री कर देने पर आपके बैंक बिल तैयार हो जायेंगे।
        <li>छात्रवृत्ति का गोषवारा पत्रक तैयार करा जा सकता है।</li>
        आपकी शाला में सभी पात्र छात्रों की छात्रवृत्ति का गोषवारा पत्रक जिसमें जातिवार स्वीकृत छात्रवृत्ति, वितरण आदि की जानकारी का गोषवारा पत्रक भी तैयार करा जा सकता है।
        <li>छात्रवृत्ति वितरण पत्रक।</li>
        जब हम छात्रों को छात्रवृत्ति का वितरण करते है तब हमें उनकी प्राप्ति लेना पड़ती है। साफ्टवेयर के द्वारा छात्रवृत्ति वितरण पत्रक भी प्रिंट किया जा सकता है। जिसमें छात्रों पूर्ण जानकारी रहेगी सिर्फ चेक क्रमांक एवं वितरण दिनांक भरकर उस पर छात्रों के या पालकों के प्राप्ति हस्ताक्षर लेकर आप अपना रेकार्ड रख सकते हैं।
        <li>बच्चों का सतत मूल्यांकन (मासिक टेस्ट)जिसका रेकार्ड हम परिषिष्ट 6 में माहवार इन्ट्री करके रखते है। इसी के आधार पर हम परीक्षाफल तैयार करते है। साफ्टवेअर के परिषिष्ठ 6 में माहवार बच्चों के मूल्यांकन की इन्ट्री कर देने पर साफ्टवेअर के द्वारा निम्नलिखित पत्रक स्वतः तैयार कर दिये जायेंगे।</li>
        विद्यालय के द्वारा छात्र / छात्रा का माहवार किये जाने वाले मूल्यांकन की जानकारी (प्रपत्र 6 अ,ब) पूर्ण रूप से भर देने के पश्चात सॉफ्टवेयर द्वारा शाला के समस्त मूल्यांकन प्रपत्र जैसे परीक्षाफल पत्रक(प्रपत्र 7 अ,ब), तथा गोषवारा पत्रक (प्रपत्र 8 अ,ब) एवं प्रत्येक छात्र / छात्रा की अंकसूची स्वत: तैयार कर दी जाती है। साथ ही कक्षा 8 वीं उत्तीर्ण छात्रों के प्रारंभिकशिक्षा पूर्णता का प्रमाण पत्र भी तैयार हो जाता है।
        प्रत्येक छात्र / छात्रा का मूल्यांकन एडिट कर उसमें माह के अंक एवं ग्रेड आदि की जानकारी प्रविष्ठी कर पुन: सेव कर सकते है। उपरोक्त कार्य यदि विद्यालय के द्वारा किया जाता है तो उन्हें छात्रों का मूल्यांकन रेकार्ड हमेषा ऑनलाइन उपलब्ध मिलेगा एवं वार्षिक मूल्यांकन के माह की प्रविष्ठी के पश्चात परीक्षाफल पत्रक, कक्षावार गोषवारा, छात्रों की अंकसूचियां आदि साफ्टवेअर के द्वारा स्वत: जनरेट कर दी जायेगी। आपको कम्प्यूटरीकृत हार्डकापी के रूप में प्रिंट प्राप्त होगी। जिससे विद्यालय द्वारा अपनी जानकारी वरिष्ठ कार्यालय को समयसीमा में उपलब्ध कराई जा सकती है।
        <li>परीक्षाफल पत्रक (परिषिष्ठ 7)</li>
        <li>परीक्षाफल गोषवारा पत्रक (परिषिष्ठ 8)</li>
        <li>प्रत्येक बच्चे का (प्रगति पत्रक) अंकसूची भी तैयार कर दी जायेगी।</li>
        <li>कक्षा 8 वीं उत्तीर्ण बच्चों के लिये प्रारंभिक षिक्षा का प्रमाण पत्र भी तैयार कर दिया जाता है।</li>
        <li>यदि कोई छात्र या छात्रा आपके यहां से स्थानांतरण होकर अन्य शाला में जाता है तो उसका स्थानांतरण प्रमाण पत्र (टीसी) भी तैयार करा जा सकता है।</li>
      </ul>
        <p></p>
        <p>
          इसके अतिरिक्त भविष्य में साफ्टवेयर के माध्यम से आप बच्चों के पालकों को उनकी प्रगति की जानकारी या विषेष सूचनाओं के लिये एसएमएस भी कर सकेंगे। आपके कर्मचारी-वर्ग(स्टाफ़) की जानकारी को भी अपडेट कर सकते हैं, इसके अलावा अन्य सुविधाओं का लाभ भी आपको मिल सकेगा।
        </p>


      </fieldset>


    </section>
  </Layout>
);


export default Page;


/*
-OR-

const AboutUsPage = () => (
const AboutUsPage = function aboutUsPage(){ return ( ... ); }
export default AboutUsPage;
  */


/*
          <p>
          Welcome to Parakh Shikshan Sewa Sanstha®,
          </p>

     <br/>
    अब हमारी वेबसाइड <a href="http://web.parakh.me">http://web.parakh.me</a> पर जांए!
     <br/>
      -->
 */
